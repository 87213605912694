@tailwind base;
@tailwind components;
@tailwind utilities;

@keyframes moving-gradient {
    0% {
        background-position: 0% 0%;
    }
    50% {
        background-position: 100% 100%;
    }
    100% {
        background-position: 0% 0%;
    }
}

body {
    background: linear-gradient(45deg, #ff7f7f, #7fff7f, #7f7fff);
    background-size: 200% 200%;
    animation: moving-gradient 5s ease infinite;
}